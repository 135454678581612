import { useEffect } from "react";
import { useRouter } from "next/router";

import * as ga from "@utils/ga";

require("@fortawesome/fontawesome/styles.css");
//import "bootstrap/dist/css/bootstrap.css";

import "@styles/tour.css";
import "@styles/get-scythe.css";
import "../styles/globals.css";
import Head from "next/head";


function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossOrigin="anonymous"
        />
    </Head>
    <Component {...pageProps} />
  </>
  );
}

export default MyApp;
